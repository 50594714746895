@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  --primary-color: #c23f33;
}

body {
  margin: 0;
  font-size:18px;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444444;
}

p {
  font-size:18px;
}

h1, h2, h3, h4, .amplify-heading {
  margin: 5px 0 5px;
  text-transform: uppercase!important;
  font-family: "Montserrat"!important;
  letter-spacing: 4px;
  font-weight: 700;
  letter-spacing: 4px;
}

.navbar {
  background-color:white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  top:0;
  left:0;
}

.navbar .nav-link,
.navbar .navbar-brand {
  font-family: 'Montserrat';
  letter-spacing: 3px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.navbar .nav-link {
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: .8rem;
}

.navbar .nav-link .icon {
  width:25px;
  height:25px;
  margin-right:5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .nav-link .icon svg {
  width:17.5px;
}

.hero {
  height:calc(100vh - 57px);
  background-image:url('homepage_bg.png');
  background-size:cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero h1 {
  color:#FFF;
  text-shadow: 2px 2px #000000;
  margin-bottom:20px;
}

.btn, .amplify-button--primary {
  font-family: 'Montserrat';
  font-weight: bold!important;
  text-transform: uppercase;
  letter-spacing: 4px;
  padding: 1rem 3rem!important;
  margin:10px;
  background-color: #c23f33!important;
  border:0px!important;
  border-radius:0px!important;
  width:auto!important;
  vertical-align: middle;
}

.amplify-button--link {
  color: #c23f33!important;
}

.card {
  border-radius:0px!important;
}

.card .btn {
  padding: 0.5rem 2rem!important;
  margin:auto;
  display: block;
  width:100%!important;
}

.btn-primary {
  background-color: #c23f33!important;
  display: inline-block!important;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

.amplify-pagination__item-current {
  background-color: #c23f33!important;
}

.amplify-input {
  border-radius: 0px!important;
}